import PropTypes from "prop-types"

import Flex from "components/core/Flex"
import styles from "./styles.module.scss"

export const propTypes = {
  height: PropTypes.string,
  containerStyle: PropTypes.object,
  loaderStyle: PropTypes.object,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

const SkeletonLoader = (props) => (
  <Flex className={styles.skeletonLoader} {...props}>
    <div className={styles.loadingLine} />
  </Flex>
)

SkeletonLoader.propTypes = propTypes

export default SkeletonLoader
