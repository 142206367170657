import React, { useRef, useState, forwardRef, useEffect } from "react"
import PropTypes from "prop-types"

import { SearchField } from "components/core/Forms"
import Section from "components/core/Section"
import Flex from "components/core/Flex"
import Text from "components/core/Text"

import useTranslation from "hooks/useTranslation"
import { useFilteredItems } from "components/core/DynamicDropdown/hooks"
import useDebounce from "hooks/useDebounce"
import useClickOutside from "hooks/useClickOutside"

import classNames from "classnames"
import styles from "./PaginatedSearchField.module.scss"

const propTypes = {
  value: PropTypes.string,
  items: PropTypes.array,
  onItemClick: PropTypes.func,
  autoFocusOnRender: PropTypes.bool,
  onFirstRender: PropTypes.func,
  searchFieldProps: PropTypes.object,
  listContainerProps: PropTypes.object,
}

const PAGE_SIZE = 30

const PaginatedSearchField = forwardRef(
  (
    {
      value = "",
      items = [],
      onItemClick = () => {},
      autoFocusOnRender = true,
      onFirstRender = () => {},
      searchFieldProps = {},
      listContainerProps = {},
      ...rest
    },
    ref
  ) => {
    const [showList, setShowList] = useState(autoFocusOnRender)
    const [currentPage, setCurrentPage] = useState(0)
    const [searchTerm, setSearchTerm] = useState("")
    const [paginatedItems, setPaginatedItems] = useState(items)

    const listRef = useRef(null)
    const searchRef = ref || useRef(null)
    const containerRef = useClickOutside(() => setShowList(false))

    const debouncedSearchTerm = useDebounce(searchTerm, 400)
    const [itemsMatchingTerm] = useFilteredItems({
      items,
      searchTerm: debouncedSearchTerm,
      itemKeyToFilter: "label",
    })

    const { t: trans } = useTranslation("common")

    useEffect(() => {
      const newMatching = itemsMatchingTerm.slice(
        0,
        currentPage * PAGE_SIZE + PAGE_SIZE
      )

      setPaginatedItems(newMatching)
    }, [currentPage, JSON.stringify(itemsMatchingTerm)])

    useEffect(() => {
      setCurrentPage(0)
    }, [debouncedSearchTerm])

    useEffect(() => {
      onFirstRender()
      autoFocusOnRender && searchRef.current?.focus()
    }, [])

    useEffect(() => {
      setSearchTerm(value)
    }, [value])

    const onScroll = () => {
      if (listRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listRef.current

        if (scrollTop + clientHeight >= scrollHeight - 100) {
          setCurrentPage((prev) => prev + 1)
        }
      }
    }

    const { wpClassName: searchWpClassName, ...searchProps } = searchFieldProps
    const {
      className: list_className,
      itemClassName: listItem_className,
      labelDetailsClassName: listItem__labelDetails__className,
      ...listProps
    } = listContainerProps

    return (
      <Section ref={containerRef} {...rest}>
        <SearchField
          ref={searchRef}
          onFocus={() => !showList && setShowList(true)}
          wpClassName={classNames(
            styles.searchField,
            {
              [styles.borderShowList]: showList,
              [styles.borderHideList]: !showList,
            },
            searchWpClassName
          )}
          value={searchTerm}
          onChange={({ target }) =>
            target ? setSearchTerm(target.value) : setSearchTerm("")
          }
          placeholder={trans("Search coordinate reference system")}
          {...searchProps}
        />

        {showList ? (
          <Section
            ref={listRef}
            className={classNames(styles.list__container, list_className)}
            p="xs"
            onScroll={onScroll}
            {...listProps}
          >
            {paginatedItems.length === 0 ? (
              <Text>{trans("No results found")}</Text>
            ) : null}

            {paginatedItems.map((item) => (
              <Flex
                className={classNames(styles.list__item, listItem_className)}
                key={item.key || `${item.label}-${item.id}`}
                justifyContent="spaceBetween"
                onClick={() => {
                  onItemClick(item)
                  setShowList(false)
                  setSearchTerm(item?.label || "")
                }}
              >
                <Text pointer pB="s">
                  {item.label}
                </Text>
                <Section
                  pointer
                  mR="m"
                  className={listItem__labelDetails__className}
                >
                  {item?.labelDetails}
                </Section>
              </Flex>
            ))}
          </Section>
        ) : null}
      </Section>
    )
  }
)

PaginatedSearchField.displayName = "PaginatedSearchField"
PaginatedSearchField.propTypes = propTypes

export default PaginatedSearchField
