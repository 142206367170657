import { useState } from "react"
import { testValidInteger, testValidNumber } from "helpers/number"
import eventsHelpers from "helpers/eventsHelpers"
import styles from "./NumberField.module.scss"

const isValid = (value, min, max, isInteger) =>
  (isInteger ? testValidInteger(value) : testValidNumber(value)) &&
  (min === undefined || value >= min) &&
  (max === undefined || value <= max)

export const useNumberFieldState = ({
  min,
  max,
  value,
  size,
  autofocusNext,
  onChange,
  onBlur = () => {},
  resizable,
  overwriteCallback,
  allowSpecialChars,
  allowedCharsRegex,
  textEllipsis,
  propertyInput,
  isInteger,
  replace,
}) => {
  const [valid, setValid] = useState(isValid(value, min, max))
  const [isEditing, setIsEditing] = useState(false)

  const toggleEditing = () => setIsEditing(!isEditing)

  const onChangeFn = overwriteCallback
    ? onChange
    : (event) => {
        const { target, nativeEvent } = event
        const newValue = target.value
        let newValid = isValid(newValue, min, max, isInteger)
        const newChar = nativeEvent.data
        if (newValid) {
          onChange(newValue)
          setValid(newValid)
        } else if (allowSpecialChars) {
          if (newValue == Number(newValue) || (newValue == "-" && min < 0)) {
            onChange(newValue)
          } else if (newValue == ".") {
            onChange("0.")
          }
          setValid(newValid)
        } else if (newChar == "-" && isValid(-value, min, max, isInteger)) {
          onChange(-value)
        } else if (replace && isValid(newChar, min, max, isInteger)) {
          onChange(Number(newChar), event)
        }
      }

  const getSizeMultiplier = () => (size == "md" ? 10 : size === "sm" ? 7 : 15)

  const increseControl = () =>
    max ? onChange(Math.min(value + 1, max)) : onChange(++value)
  const decreaseControl = () =>
    min ? onChange(Math.max(value - 1, min)) : onChange(--value)

  const onBlurFn =
    textEllipsis && allowSpecialChars
      ? () => {
          propertyInput.current?.setSelectionRange(0, 0)
          toggleEditing()
          onBlur()
        }
      : () => {
          toggleEditing()
          onBlur()
        }

  const onFocus = () =>
    setTimeout(() => {
      allowSpecialChars &&
        propertyInput.current?.setSelectionRange(
          value.toString().length,
          value.toString().length
        )
    }, 2)

  const onInputKeyPress = (e) => {
    if (e.key == "Enter")
      autofocusNext
        ? eventsHelpers.focusNext(e, size ? `.${styles[`input-${size}`]}` : null)
        : e.target.blur()

    if (allowedCharsRegex && !allowedCharsRegex.test(e?.key)) {
      e.preventDefault()
      return
    }

    // Allow only numbers and special charaters that are used for comparisons or - for sending a null value or `.`
    if (!/[0-9<><=>=.]|-|\./.test(e?.key)) {
      e.preventDefault()
    }
  }

  const getInputWidth = () =>
    resizable
      ? (value ? value.toString().length + 2 : 5) * getSizeMultiplier() + "px"
      : "inherit"

  return {
    valid,
    isEditing,
    onChangeFn,
    increseControl,
    decreaseControl,
    onBlur: onBlurFn,
    onFocus,
    onInputKeyPress,
    toggleEditing,
    getInputWidth,
  }
}
