import classNames from "classnames"
import PropTypes from "prop-types"

import {
  fontSizeClassNames,
  fontSizePropTypes,
} from "components/core/helpers/typography"

import styles from "./CheckboxField.module.scss"

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  markSize: PropTypes.string,
  onContainerClick: PropTypes.func,
  labelSize: PropTypes.oneOf(fontSizePropTypes),
}

const CheckboxField = ({
  id,
  name,
  onChange,
  onContainerClick = () => {},
  checked,
  label,
  title = "",
  disabled,
  labelSize,
  className,
  markSize,
  ...rest
}) => {
  return (
    <div
      className={classNames("mb-10", className)}
      onClick={(e) => onContainerClick({ e, id, label, name, checked })}
      title={title}
      {...rest}
    >
      <input
        className={styles.checkbox}
        type="checkbox"
        id={id}
        name={name || id}
        checked={!!checked}
        disabled={disabled}
        onChange={onChange}
      />
      <div>
        <label
          htmlFor={id}
          className={classNames(styles.mark, {
            [styles.s]: markSize === "s",
            [styles.m]: markSize === "m",
            [styles.l]: markSize === "l",
            [styles.xl]: markSize === "xl",
          })}
        ></label>
        <label
          htmlFor={id}
          className={classNames(styles.text, {
            ...fontSizeClassNames(labelSize),
          })}
        >
          {label}
        </label>
      </div>
    </div>
  )
}
CheckboxField.propTypes = propTypes

export default CheckboxField
