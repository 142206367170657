import PropTypes from "prop-types"
import classNames from "classnames"

import { wordBreakPropTypes } from "components/core/helpers/typography"
import Flex from "components/core/Flex"
import Text from "components/core/Text"

import styles from "./styles.module.scss"

const propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.number,
    sufix: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.elementType,
    ]),
  }),
  onOptionClick: PropTypes.func,
  optionClass: PropTypes.string,
  selectedOption: PropTypes.object,
  selectedOptionClass: PropTypes.string,
  wordBreak: PropTypes.oneOf(wordBreakPropTypes),
}

const DefaultCustomSelectOption = ({
  option,
  onOptionClick,
  optionClass,
  selectedOption,
  selectedOptionClass,
  wordBreak = "breakWord",
}) => (
  <Flex
    onClick={(e) => (e.defaultPrevented ? null : onOptionClick(option))}
    key={option.value}
    width="full"
    justifyContent="spaceBetween"
    className={classNames("pointer", styles.customSelect__option, optionClass)}
  >
    <Text
      className={classNames({
        [selectedOptionClass]: selectedOption?.value === option.value,
        [styles.customSelect__selectedOption]:
          selectedOption?.value === option.value,
      })}
      wordBreak={wordBreak}
    >
      {option.label}
    </Text>
    {option.sufix ? option.sufix : null}
  </Flex>
)

DefaultCustomSelectOption.propTypes = propTypes

export default DefaultCustomSelectOption
