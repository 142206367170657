import { useEffect, useRef } from "react"

const useClickOutside = (callback) => {
  const callbackRef = useRef(null)
  const innerRef = useRef(null)
  const preventCb = useRef(false)

  // update cb on each render, so last useEffect has access to current value
  useEffect(() => {
    callbackRef.current = callback
    return () => {
      preventCb.current = false
    }
  })

  // this prevent elements unmounted from DOM, that were in the innerRef, trigger the callback.
  useEffect(() => {
    if (!innerRef.current) return
    innerRef.current.onmousedown = (e) => {
      if (innerRef.current !== e.target) preventCb.current = true
    }
    return () => {
      if (innerRef.current) innerRef.current.onmousedown = null
    }
  }, [innerRef.current])

  useEffect(() => {
    document.addEventListener("click", onDocumentClick, true)
    return () => document.removeEventListener("click", onDocumentClick)
    function onDocumentClick(e) {
      const elementInDOM = e.target.closest("body")
      const clickedOutside = !innerRef.current?.contains(e.target)
      if (clickedOutside || (!elementInDOM && preventCb.current))
        callbackRef.current?.(e)
      if (preventCb.current) preventCb.current = false
    }
  }, []) // no dependencies -> stable click listener

  return innerRef // convenience for client (doesn't need to init ref himself)
}

export default useClickOutside
