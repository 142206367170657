import React, { useRef, useEffect } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import PropTypes from "prop-types"
import Link from "next/link"

import { TextFieldFormik } from "components/core/FormikFields"
import Button from "components/core/Button"
import { UnlockIcon, QuestionMarkIcon } from "components/core/SVGs"
import useTranslation from "hooks/useTranslation"

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don‘t match")
    .required("Password confirmation is required"),
})

const propTypes = {
  /** function that will be called on submit */
  onSubmit: PropTypes.func,
  /** flag that disables/activates submit button */
  submitPending: PropTypes.bool,
}

function SignupForm({ onSubmit, submitPending }) {
  const nameInputRef = useRef()
  const { t: trans } = useTranslation("common");

  useEffect(() => {
    nameInputRef.current?.focus()
  }, [])

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirmation: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <div className="row row-sm">
            <div className="col-md-6">
              <Field
                innerRef={nameInputRef}
                name="firstName"
                component={TextFieldFormik}
                type="text"
                label={trans("First name")}
                placeholder={trans("First name")}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="lastName"
                component={TextFieldFormik}
                type="text"
                label={trans("Last name")}
                placeholder={trans("Last name")}
              />
            </div>
          </div>
          <Field
            name="email"
            component={TextFieldFormik}
            type="email"
            label={trans("Email")}
            placeholder={trans("Email")}
          />
          <Field
            name="password"
            component={TextFieldFormik}
            type="password"
            label={
              <>
                {trans("Password")}
                <QuestionMarkIcon
                  style={{ margin: "0 0 -2px 3px" }}
                  title={trans("Use at least 8 characters with a mix of letters, numbers & symbols")}
                />
              </>
            }
            placeholder={trans("Password")}
          />
          <Field
            name="passwordConfirmation"
            component={TextFieldFormik}
            type="password"
            label={trans("Password Confirmation")}
            placeholder={trans("Password Confirmation")}
          />
          <div className="mt-50">
            <Button
              loading={submitPending}
              leftIcon={<UnlockIcon />}
              title={trans("Sign up")}
              type="submit"
              fullWidth
            />
          </div>
          <div className="mt-40 fw-500 f-16 text-center">
            <span className="light">{trans("Already have an account?")}</span>
            <Link href="/login">
              <a className="link ml-10">{trans("Log in")}</a>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}

SignupForm.propTypes = propTypes

export default SignupForm
