import { createRef } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { ChevronDownIcon, ChevronUpIcon } from "components/core/SVGs"
import { IntlNumberFormat } from "helpers/number"

import Flex from "components/core/Flex"
import { useNumberFieldState } from "./useNumberFieldState"
import styles from "./NumberField.module.scss"

const sizeTypes = ["md", "sm", "lg"]
const styleTypes = ["default", "blank"]

const propTypes = {
  /** placeholder text */
  placeholder: PropTypes.string,
  /** can send a classes for wrapper. If we want to have a different bg color
   * we can send a different class
   */
  wpClassName: PropTypes.string,
  /** Used to add extra style to input */
  inputClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  resizable: PropTypes.bool,
  /** normally callback is called with the value.
   * If any other prop from event is needed set to false
   */
  overwriteCallback: PropTypes.bool,
  /**
   * There are some cases where we need number input that accepts comparison characters
   * If the type of the input is number, the comparison characters are not allowed
   * We use this key to fake a number input, and we use onKeyPress to assure that only
   * numbers and comparison characters are added
   */
  allowSpecialChars: PropTypes.bool,
  /**
   * You can send a custom regex if you want to test against something very custom
   */
  allowedCharsRegex: PropTypes.object,
  /** if field sholdn't accept decimals set to true */
  isInteger: PropTypes.bool,
  /** in case the next value is out of rage, if the current value is valid and this is set to true it will replace the old one
   *
   * ex scenario (with replace == true): if min == 1, max == 7, value == 3;
   * the user press 5 35 won't be valid so the value will be replaced with value 5 instead
   * after if he press 9, 59 is not valid but neighter is 9 so the value stays 5
   */
  replace: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  /** set to true to make increase and decrease value buttons available */
  controls: PropTypes.bool,
  /** input size (height, font-size, etc) */
  size: PropTypes.oneOf(sizeTypes),
  /** input style (bank, default, etc) */
  defaultStyle: PropTypes.oneOf(styleTypes),
  /** textEllipsis makes text that overflow to be displayed ellipsis on blur */
  textEllipsis: PropTypes.bool,
  /** try to focus next input (same size) when enter is pressed */
  autofocusNext: PropTypes.bool,
  style: PropTypes.object,
  extraRender: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    position: PropTypes.oneOf(["top", "bottom"]),
    style: PropTypes.object,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  preventFormat: PropTypes.bool,
  language: PropTypes.string,
}

// TODO add functions for input events
const NumberField = ({
  placeholder = "",
  wpClassName = "bg-grey",
  inputClassName = "fw-500",
  controls = true,
  size = "md",
  defaultStyle = "default",
  textEllipsis = false,
  preventFormat = false,
  language,
  style = {},
  extraRender,
  ...props
}) => {
  const propertyInput = createRef()

  const {
    valid,
    isEditing,
    onChangeFn,
    increseControl,
    decreaseControl,
    onBlur,
    onFocus,
    onInputKeyPress,
    toggleEditing,
    getInputWidth,
  } = useNumberFieldState({ textEllipsis, size, propertyInput, ...props })

  const { min, max, value = "", allowSpecialChars } = props

  const inputClassNames = classNames(
    styles.searchField__input,
    styles[`input-${size}`],
    styles[`input-${defaultStyle}`],
    { [styles.ellipsis]: textEllipsis },
    inputClassName
  )

  return (
    <div
      className={classNames(
        styles.searchField,
        styles[`container-${size}`],
        wpClassName,
        { [!valid]: styles.invalid }
      )}
    >
      <Flex column justifyContent="spaceBetween">
        {extraRender?.position == "top" && (
          <div style={extraRender.style} className={extraRender.className}>
            {extraRender.value}
          </div>
        )}
        {isEditing || preventFormat ? (
          <input
            ref={propertyInput}
            onChange={onChangeFn}
            style={{
              width: getInputWidth(),
              ...style,
            }}
            value={value}
            className={inputClassNames}
            type={allowSpecialChars ? "text" : "number"}
            inputMode={allowSpecialChars ? "decimal" : "numeric"}
            onKeyPress={onInputKeyPress}
            placeholder={placeholder}
            autoComplete="off"
            max={max}
            min={min}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        ) : (
          <input
            type="text"
            style={{
              width: getInputWidth(),
              ...style,
            }}
            value={IntlNumberFormat(value, language)}
            className={inputClassNames}
            placeholder={placeholder}
            onFocus={toggleEditing}
            readOnly
          />
        )}
      </Flex>

      {controls && (
        <div className={styles.controls}>
          <div
            className={classNames({ [styles.disabled]: value == max })}
            onClick={increseControl}
          >
            <ChevronUpIcon />
          </div>
          <div
            className={classNames({ [styles.disabled]: value == min })}
            onClick={decreaseControl}
          >
            <ChevronDownIcon />
          </div>
        </div>
      )}
    </div>
  )
}

NumberField.propTypes = propTypes

export default NumberField
