var defaultFocusableElementsString =
  "input:not([disabled]), select:not([disabled]), textarea:not([disabled])"

const preventDefault = (event) => event.preventDefault()
const stopPropagation = (event) => event.stopPropagation()
const cancellAllDefault = (event) => {
  event.preventDefault()
  event.stopPropagation()
}

const focusNext = (ev, focusableElementsString = defaultFocusableElementsString) => {
  let ol = document.querySelectorAll(focusableElementsString)
  for (let i = 0; i < ol.length; i++) {
    if (ol[i] === ev.target) {
      let o = i < ol.length - 1 ? ol[i + 1] : ol[0]
      o.focus()
      break
    }
  }
  ev.preventDefault()
}

export default { preventDefault, stopPropagation, cancellAllDefault, focusNext }
