import classNames from "classnames"
import PropTypes from "prop-types"
import styles from "./SwitchField.module.scss"

const DEFAULT_SIZES = ["xs", "sm", "md", "lg", "xl"]
const VARIANTS = ["dark"]

const propTypes = {
  /** placeholder text */
  placeholder: PropTypes.string,
  /** can send a classes for wrapper. If we want to have a different bg color
   * we can send a different class
   */
  wpClassName: PropTypes.string,
  /** Used to add extra style to input */
  inputClassName: PropTypes.string,
  /**onChageFunction is required but the SwitchField won't crash the app if is not provided. Only the warning will show on console.
   * this might change when we finish onActive functionality.
   */
  onChange: PropTypes.func,
  onActive: PropTypes.func,
  overwriteCallback: PropTypes.bool,
  checked: PropTypes.bool,
  color: PropTypes.string,
  size: PropTypes.oneOf(DEFAULT_SIZES),
  variant: PropTypes.oneOf(VARIANTS),
  disabled: PropTypes.bool,
}

const SwitchField = ({
  onActive,
  overwriteCallback,
  wpClassName,
  checked,
  inputClassName,
  onChange,
  color,
  disabled,
  size,
  variant,
}) => {
  let onChangeFn = () => {},
    onActiveFn = (e) => e.target.checked && onActive?.(e)
  if (typeof onChange == "function" && typeof onActiveFn == "function") {
    onChangeFn = (e) => {
      onActiveFn(e)
      overwriteCallback ? onChange(e) : onChange(e.target.checked)
    }
  } else if (typeof onChange == "function") {
    onChangeFn = (e) =>
      overwriteCallback ? onChange(e) : onChange(e.target.checked)
  } else if (typeof onActiveFn == "function") {
    onChangeFn = onActiveFn
  }

  return (
    <label
      className={classNames(
        styles.switch,
        wpClassName,
        styles[`switch__${size}`],
        styles[variant],
        {
          [styles.disabled]: disabled,
        }
      )}
    >
      <input
        className={classNames(inputClassName, { [styles[color]]: color })}
        checked={checked}
        onChange={disabled ? null : onChangeFn}
        type="checkbox"
      />
      <span className={styles.slider}></span>
    </label>
  )
}

SwitchField.propTypes = propTypes

export default SwitchField
