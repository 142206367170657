import React, { useEffect, useRef } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import PropTypes from "prop-types"
import Link from "next/link"

import styles from "styles/pages/forgot-password.module.scss"
import { TextFieldFormik } from "components/core/FormikFields"
import Button from "components/core/Button"
import Section from "components/core/Section"
import Text from "components/core/Text"

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
})

const propTypes = {
  /** function that will be called on submit */
  onSubmit: PropTypes.func,
  /** flag that disables/activates submit button */
  submitPending: PropTypes.bool,
}

function ForgotPasswordForm({ onSubmit, submitPending }) {
  const emailInputRef = useRef()

  useEffect(() => {
    emailInputRef.current?.focus()
  }, [])

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={ForgotPasswordSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Field
            innerRef={emailInputRef}
            name="email"
            component={TextFieldFormik}
            type="email"
            label="Email"
            placeholder="Enter your email"
          />

          <Section className={styles.buttonWp}>
            <Button loading={submitPending} title="Send" type="submit" fullWidth />
          </Section>

          <Text size="m" fontWeight="medium" className="mt-40" fontFamily="Roboto">
            <span className="light">Remember Password?</span>
            <Link href="/login">
              <a className="link ml-10">Log In</a>
            </Link>
          </Text>
        </Form>
      )}
    </Formik>
  )
}

ForgotPasswordForm.propTypes = propTypes

export default ForgotPasswordForm
