import React, { useRef, useEffect } from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import PropTypes from "prop-types"
import Link from "next/link"

import { TextFieldFormik } from "components/core/FormikFields"
import Button from "components/core/Button"
import { UnlockIcon } from "components/core/SVGs"

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
})

const propTypes = {
  /** function that will be called on submit */
  onSubmit: PropTypes.func,
  /** flag that disables/activates submit button */
  submitPending: PropTypes.bool,
}

function LoginForm({ onSubmit, submitPending }) {
  const emailInputRef = useRef()

  useEffect(() => {
    emailInputRef.current?.focus()
  }, [])

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={LoginSchema}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Field
            innerRef={emailInputRef}
            name="email"
            component={TextFieldFormik}
            type="email"
            label="Email"
            placeholder="Email"
          />
          <Field
            name="password"
            component={TextFieldFormik}
            type="password"
            label="Password"
            placeholder="Password"
          />
          <div className="text-right light">
            <Link href="/forgot-password">Forgot your password?</Link>
          </div>
          <div className="mt-30">
            <Button
              loading={submitPending}
              leftIcon={<UnlockIcon />}
              title="Log in"
              type="submit"
              fullWidth
            />
          </div>
          <div className="mt-40 fw-500 f-16 text-center">
            <span className="light">New to Urbio?</span>
            <Link href="/signup">
              <a className="link ml-10">Sign up</a>
            </Link>
          </div>
        </Form>
      )}
    </Formik>
  )
}

LoginForm.propTypes = propTypes

export default LoginForm
