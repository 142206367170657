import { NUMBER_FORMAT_LANGUAGE_CODES } from "config/constants"

export const formatNumber = (value, locale = "en-US") => {
  if (value === "null" || value === undefined) {
    return ""
  }
  return Intl.NumberFormat(NUMBER_FORMAT_LANGUAGE_CODES[locale], {
    maximumFractionDigits: 3,
  }).format(value)
}

export const IntlNumberFormat = (val, lng) => {
  if (!lng) lng = localStorage.getItem("defaultLanguage") || ""
  try {
    return Intl.NumberFormat(NUMBER_FORMAT_LANGUAGE_CODES[lng]).format(val)
  } catch (e) {
    console.warn(`Couldn't format ${val} because: `, e)
    return ""
  }
}

export const formatBuildingAttribute = (value, legend, language) => {
  if (legend.data_type === "categorical") {
    const selectedLegendOption = legend.options.find((option) => option.id == value)
    return selectedLegendOption?.label || value
  }
  const shouldFormatAttribute = !["date", "string", "boolean"].includes(
    legend.data_type
  )
  return shouldFormatAttribute ? formatNumber(value, language) : value
}

export const between = (a, b, value, matchEdges = true) => {
  const min = Math.min(a, b),
    max = Math.max(a, b)
  if (!value) value = this
  return matchEdges ? value >= min && value <= max : value > min && value < max
}

export const testValidNumber = (number) => /^-?(0|[1-9]\d*)(\.\d+)?$/.test(number)

export const testValidInteger = (number) => /^-?(0|[1-9]\d*)$/.test(number)

export const testValidFloat = (float) =>
  /^-?([0-9]{1,})?(\.)?([0-9]{1,})?$/.test(float)
