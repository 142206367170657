import { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import colors from "styles/colors.module.scss"
import Flex from "components/core/Flex"
import { ArrowUpIcon, ArrowDownIcon } from "components/core/SVGs"
import CheckboxField from "components/core/Forms/fields/CheckboxField"

import styles from "./SelectField.module.scss"

const propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  options: PropTypes.array,
  bordered: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  wpClassName: PropTypes.string,
  optionsClassName: PropTypes.string,
  optiontitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onOpenChange: PropTypes.func,
  extraRender: PropTypes.any,
  loading: PropTypes.bool,
  CustomIcon: PropTypes.elementType,
}

const itemPropTypes = {
  option: PropTypes.object,
  index: PropTypes.number,
  optiontitle: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
}

const Item = ({ multiple, option, index, optiontitle, onChange }) => {
  const getCheckboxOptionProps = ({ id, name, label, checked }) => ({
    id,
    name,
    label: label || name,
    checked: !!checked,
  })
  return multiple ? (
    <CheckboxField
      title={optiontitle ? optiontitle(option) : null}
      key={option.key || index}
      className={styles.selectOption}
      onContainerClick={onChange}
      onChange={() => {}}
      {...getCheckboxOptionProps(option)}
    />
  ) : (
    <div
      onClick={() => onChange(option)}
      key={option.key || index}
      className={styles.selectOption}
    >
      {option.name}
    </div>
  )
}

Item.propTypes = itemPropTypes

function Select({
  label,
  value,
  className,
  options = [],
  optionsClassName,
  optiontitle,
  bordered,
  multiple,
  onChange,
  onOpenChange = () => {},
  extraRender,
  loading,
  wpClassName,
  CustomIcon,
  ...rest
}) {
  const [open, setOpen] = useState(false)
  const selectRef = useRef()
  useEffect(() => {
    if (!selectRef) return
    const closeSelect = (e) => {
      e.preventDefault()
      !selectRef.current?.contains(e.target) && setOpen(false)
    }
    if (open) {
      document.body.addEventListener("click", closeSelect)
    } else {
      document.body.removeEventListener("click", closeSelect)
    }
    onOpenChange(open)
    return () => {
      document.body.removeEventListener("click", closeSelect)
      window.removeEventListener("click", closeSelect)
    }
  }, [open])

  return (
    <div
      ref={selectRef}
      className={classNames(styles.select, wpClassName, {
        [styles.bordered]: bordered,
      })}
      {...rest}
    >
      <div className={classNames(styles.label, className)}>
        <Flex
          justifyContent="spaceBetween"
          style={{ flexGrow: 1 }}
          onClick={(e) => {
            e.preventDefault()
            setOpen(!open)
          }}
        >
          <span>{value || label}</span>
          {CustomIcon ? (
            <CustomIcon open={open} />
          ) : open ? (
            <ArrowUpIcon color={colors.primary} width="10" height="9" />
          ) : (
            <ArrowDownIcon />
          )}
        </Flex>
        {open && !loading ? (
          <div
            className={classNames(styles.selectOptions, optionsClassName)}
            onClick={(e) => (multiple ? e.stopPropagation() : setOpen(!open))}
          >
            <section>
              {options.map((option, index) => (
                <Item
                  key={`item-${index}`}
                  option={option}
                  index={index}
                  optiontitle={optiontitle}
                  onChange={onChange}
                  multiple={multiple}
                />
              ))}
            </section>
            {extraRender}
          </div>
        ) : null}
      </div>
    </div>
  )
}

Select.propTypes = propTypes

export default Select
