import { useState, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import find from "lodash/find"
import useSelectListFullHeight from "hooks/useSelectListFullHeight"

import { wordBreakPropTypes } from "components/core/helpers/typography"
import useTranslation from "hooks/useTranslation"
import useDynamicDropDown from "hooks/useDynamicDropDown"
import Flex from "components/core/Flex"
import Text from "components/core/Section"
import Section from "components/core/Section"
import { ChevronDownIcon } from "components/core/SVGs"
import SkeletonLoader from "components/core/Loader/SkeletonLoader"

import colors from "styles/colors.module.scss"
import DefaultCustomSelectOption from "./DefaultCustomSelectOption"
import styles from "./styles.module.scss"
import useClickOutside from "hooks/useClickOutside"

const propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  className: PropTypes.string,
  customSelectStyle: PropTypes.object,
  valueWpClassName: PropTypes.string,
  optionsListContainerClassName: PropTypes.string,
  optionsListContainerStyle: PropTypes.object,
  parent: PropTypes.object,
  optionClass: PropTypes.string,
  scrolableContainerRef: PropTypes.any,
  selectedOptionClass: PropTypes.string,
  enableOptionsDisplay: PropTypes.bool,
  OptionComponent: PropTypes.elementType,
  onSelectClick: PropTypes.func,
  optionsWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
  position: PropTypes.oneOf(["auto"]),
  optionWordBreak: PropTypes.oneOf(wordBreakPropTypes),
  autoPositioning: PropTypes.bool,
}

const getSelectorPosition = (optionsPos, position) =>
  position === "auto"
    ? {
        top: "auto",
        bottom: "auto",
        left: "auto",
        right: "auto",
      }
    : optionsPos

const getDropDownPos = (optionsPos) => {
  if (!isNaN(optionsPos?.top)) return optionsPos.top
  if (!isNaN(optionsPos?.bottom)) return optionsPos.bottom
  return 0
}

const CustomSelectField = ({
  placeholder,
  value,
  onChange,
  options = [],
  className,
  customSelectStyle,
  valueWpClassName,
  optionsListContainerClassName,
  optionsListContainerStyle,
  parent,
  optionClass,
  selectedOptionClass,
  enableOptionsDisplay = true,
  OptionComponent = DefaultCustomSelectOption,
  onSelectClick = () => {},
  scrolableContainerRef,
  optionWordBreak,
  optionsWidth,
  loading,
  position,
  autoPositioning = position !== "auto",
}) => {
  const listContainerRef = useRef()
  const [showOptions, setShowOptions] = useState(false)
  const [optionsPos, setOptionsPos] = useState()
  const { trans } = useTranslation("common")

  const closeDropdown = () => setShowOptions(false)

  const selectRef = useClickOutside(closeDropdown)
  const [optionsHeight, getTopPositionBelowClicker] = useDynamicDropDown({
    scrolableContainerRef,
    elementRef: selectRef,
    position: getDropDownPos(optionsPos),
    closeDropdown,
    dropDownWidth: optionsWidth,
    autoPositioning,
  })

  const onCustomSelectClick = (e) => {
    if (e.defaultPrevented) return null
    if (!showOptions) setOptionsPos(getTopPositionBelowClicker(e))
    setShowOptions(!showOptions)
    onSelectClick(e, selectRef)
  }
  const onOptionClick = (option) => {
    onChange(option)
    closeDropdown()
  }

  const selectedOption = useMemo(() => find(options, { value }), [options, value])

  useSelectListFullHeight(parent, listContainerRef, showOptions)
  if (!placeholder) placeholder = trans("Select an option")

  return (
    <Section
      ref={selectRef}
      className={classNames(styles.customSelect, className)}
      style={customSelectStyle}
      onClick={onCustomSelectClick}
    >
      <Flex
        justifyContent="spaceBetween"
        alignItems="center"
        className={classNames(styles.customSelect__value, valueWpClassName, {
          [styles.customSelect__listOpen]: showOptions,
        })}
      >
        {loading ? (
          <SkeletonLoader />
        ) : (
          <Text
            className={classNames(styles.customSelect__selectedOptionText, {
              [styles.customSelect__selectedOption]: !!selectedOption,
            })}
            title={selectedOption && selectedOption.label}
          >
            {selectedOption ? selectedOption.label : placeholder}
          </Text>
        )}

        <Flex
          className={classNames(styles.customSelect__icon, {
            [styles.customSelect__iconListOpen]: showOptions,
          })}
        >
          <ChevronDownIcon width="10" height="10" color={colors.brandGray} />
        </Flex>
      </Flex>

      {enableOptionsDisplay && showOptions && optionsPos && (
        <Section
          ref={listContainerRef}
          style={{
            maxHeight: optionsHeight,
            maxWidth: optionsWidth || selectRef.current.offsetWidth,
            ...getSelectorPosition(optionsPos, position),
            ...optionsListContainerStyle,
          }}
          className={classNames(
            styles.customSelect__optionsListContainer,
            optionsListContainerClassName
          )}
        >
          {options?.length ? (
            <Section className={styles.customSelect__optionsList}>
              {options?.map((option, index) => (
                <OptionComponent
                  key={option.value || index}
                  option={option}
                  onOptionClick={onOptionClick}
                  optionClass={optionClass}
                  selectedOption={selectedOption}
                  selectedOptionClass={selectedOptionClass}
                  wordBreak={optionWordBreak}
                />
              ))}
            </Section>
          ) : (
            <Text p="s">{trans("No options to display")}</Text>
          )}
        </Section>
      )}
    </Section>
  )
}

CustomSelectField.propTypes = propTypes

export default CustomSelectField
