import { useCallback } from "react"
import { useEffect } from "react"

const useDynamicDropDown = ({
  scrolableContainerRef,
  elementRef,
  closeDropdown,
  position,
  minHeight = 150,
  dropDownWidth = 0,
  autoPositioning = true,
}) => {
  const onScrollFunc = useCallback(() => {
    closeDropdown()
  }, [])

  useEffect(() => {
    if (!scrolableContainerRef) return
    scrolableContainerRef.current?.addEventListener?.("scroll", onScrollFunc)

    return () =>
      scrolableContainerRef.current?.removeEventListener?.("scroll", onScrollFunc)
  }, [])

  const getNumberFromCssSize = (value) =>
    typeof value === "string" ? Number(value.replace("px", "")) : value

  const getTopPositionBelowClicker = (e) => {
    if (!dropDownWidth) dropDownWidth = elementRef.current.offsetWidth
    const targetTop = e.target.getBoundingClientRect().top
    let verticalPos = {}
    const left = elementRef.current.getBoundingClientRect?.().left
    verticalPos =
      left + getNumberFromCssSize(dropDownWidth) > window.innerWidth
        ? { right: 5, left: "auto" }
        : { left: left, right: "auto" }

    if (window.innerHeight - targetTop < minHeight && autoPositioning)
      return { bottom: window.innerHeight - targetTop, ...verticalPos }
    return { top: targetTop, ...verticalPos }
  }

  const getOptionsHeight = () =>
    typeof window !== "undefined" && window.innerHeight && position
      ? window.innerHeight - position - 35
      : 250
  const optionsHeight = getOptionsHeight()

  return [optionsHeight, getTopPositionBelowClicker]
}

export default useDynamicDropDown
