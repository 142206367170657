import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./RadioField.module.scss"
import {
  fontSizeClassNames,
  fontSizePropTypes,
} from "components/core/helpers/typography"
import eventsHelpers from "helpers/eventsHelpers"

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  title: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  markClassName: PropTypes.string,
  labelTextClassName: PropTypes.string,
  labelSize: PropTypes.oneOf(fontSizePropTypes),
  markSize: PropTypes.oneOf(["m", "l", "xl"]),
}

const RadioField = ({
  id,
  className,
  labelTextClassName,
  markSize,
  name,
  onChange,
  onClick = () => {},
  checked,
  label,
  disabled,
  labelSize,
  title,
  value,
}) => (
  <div onClick={eventsHelpers.stopPropagation} className={className} title={title}>
    <input
      className={styles.radio}
      type="radio"
      id={id}
      name={name || id}
      checked={!!checked}
      disabled={disabled}
      onChange={onChange}
      onClick={onClick}
      value={value}
    />
    <div>
      <label
        htmlFor={id}
        className={classNames(styles.mark, {
          [styles.m]: markSize === "m",
          [styles.l]: markSize === "l",
          [styles.xl]: markSize === "xl",
        })}
      ></label>
      {label && (
        <label
          htmlFor={id}
          className={classNames(styles.text, labelTextClassName, {
            ...fontSizeClassNames(labelSize),
          })}
        >
          {label}
        </label>
      )}
    </div>
  </div>
)

RadioField.propTypes = propTypes

export default RadioField
